import React, { Component } from 'react';
import axios from '../../utils/axios/axios-data';

class HeaderImage extends Component {
    mainImages = new Array("https://res.cloudinary.com/courses-site/image/upload/v1636651317/hor2_mu5jgi.png");

    state = {
        heroImages: []
    };

    getRandomHeroImage() {
        var arrayLength = this.state.heroImages.length;
        for (var i = 0; i < arrayLength; i++) {
            if (this.state.heroImages[i] != null) {
                this.mainImages.push(this.state.heroImages[i])
            }

        }
        return { 'backgroundImage': 'url(' + this.mainImages[Math.floor(Math.random() * arrayLength) + 0] + ')' };
    }

    componentWillMount() {
        axios.get('/hero-images.json')
            .then(response => {
                this.setState({ heroImages: response.data });
            });
    }


    render() {

        return (
            <div>
                <section id="hero-section" className="page-info-section set-bg" style={this.getRandomHeroImage()}>
                    <div className="container">
                        <div className="hero-text-image text-white">
                            <h2>{this.props.title}</h2>
                        </div>
                    </div>
                </section>

            </div>
        );
    }

}
export default HeaderImage;