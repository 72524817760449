import React, { Component } from 'react';
import resources from '../../utils/resources/AppLocalizedStrings';
import HeaderImage from '../HeroContent/HeaderImage';
import axios from '../../utils/axios/axios-data';
import { Route } from 'react-router-dom';
import Courses from '../Courses/Courses';
import amazon from '../../img/amazon.png';
import spotify from '../../img/spotify.png';
import youtube from '../../img/youtube.png';
import netflix from '../../img/netflix.png';

class CourseDetails extends Component {

	state = {
		course: {}
	};

	componentDidMount() {
		console.log(this.props);
		axios.get('/episodes/' + this.props.match.params.id + '.json')
			.then(response => {
				this.setState({ course: response.data });
			});
	}

	componentDidUpdte() {
		console.log(this.props);
		axios.get('/courses/' + this.props.match.params.id + '.json')
			.then(response => {
				this.setState({ course: response.data });
			});
	}

	getRecommendations() {
		let recomendations = [];
		if (this.state.course.recomendations) {
			console.log(this.state.course);
			const arrayLength = this.state.course.recomendations.length;
		for (var i = 0; i < arrayLength; i++) {
			if (this.state.course.recomendations[i] != null) {
				let logoToUse = amazon;
				if (this.state.course.recomendations[i].onSpotify === true) {
					logoToUse = spotify;
				} else if (this.state.course.recomendations[i].onYoutube === true) {
					logoToUse = youtube;
				} else if (this.state.course.recomendations[i].onNetflix === true) {
					logoToUse = netflix;
				}
				recomendations.push(
					<div class= {i === 0 ? "carousel-item active" : "carousel-item"} >
					<a href={this.state.course.recomendations[i].url} target="_blank" rel="noopener noreferrer"><img alt={this.state.course.recomendations[i].name} class="rounded mx-auto d-block" style={{ width: "auto", height: "50%" }} src={this.state.course.recomendations[i].imageUrl} /></a>
					{ this.state.course.recomendations[i].url ?
					<div className="course-author">
						<a href={this.state.course.recomendations[i].url} target="_blank" rel="noopener noreferrer"><img alt="Buy or Watch" class="rounded mx-auto d-block" src={logoToUse} /></a>
					</div> : null 
					}
				</div>
					);
			}

		}
	}
		return recomendations;
	}

	render() {
		let course = <p>loading</p>;
		if (this.state.course) {
			course = <div>
				<HeaderImage />
				<section className="course-section" >
					<div className="container">
						<div className="section-title mb-0" id="start">
							<h2>{this.state.course.episode}</h2>
						</div>
					</div>
					<div class="featured-courses">
						<div class="featured-course course-item">
							<div class="course-thumb set-bg" style={{ backgroundImage: 'url(' + this.state.course.cover + ')' }}>
								<div class="price"># {this.props.match.params.id}</div>
							</div>
							<div class="row">
								<div class="col-lg-6 offset-lg-6 pl-0">
									<div class="course-info">
										<div class="course-text">
											<div class="fet-note">{this.state.course.location}</div>
											<h5>{this.state.course.episode}</h5>
											<p>{this.state.course.fullDesc}</p>
											<div class="students">{resources.recommendations}</div><br/>
											<div className="container">
												<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
													<div class="carousel-inner">
														{this.getRecommendations()}
													</div>
													<a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
														<span class="carousel-control-prev-icon" aria-hidden="true"></span>
														<span class="sr-only">Previous</span>
													</a>
													<a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
														<span class="carousel-control-next-icon" aria-hidden="true"></span>
														<span class="sr-only">Next</span>
													</a>
												</div>
											</div>
											<br/>
											<div dangerouslySetInnerHTML={{__html: this.state.course.episodeIframe}} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Route path="/courses/" render={() => <Courses title={resources.moreCourses} />} />
			</div>;
		}
		return course;
	}

}
export default CourseDetails;