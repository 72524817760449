import React, { Component } from 'react';
import Course from './Course';
import CourseDetails from './CourseDetails';
import axios from '../../utils/axios/axios-data';
import resources from '../../utils/resources/AppLocalizedStrings';
import spotify from '../../img/spotify.png';

class Courses extends Component {
	state = {
		courses: []
	};
	componentWillMount() {
		axios.get('/episodes.json')
			.then(response => {
				this.setState({ courses: response.data });
			});
	}
	getCourses() {
		const arrayLength = this.state.courses.length;
		let courseTiles = [];
		for (var i = 0; i < arrayLength; i++) {
			if (this.state.courses[i] != null) {
				courseTiles.push(
						<Course id={i}
						name={this.state.courses[i].episode}
						description={this.state.courses[i].desc}
						imageUrl={this.state.courses[i].cover}
						spotsAvailable={this.state.courses[i].location}
						episodeUrl={this.state.courses[i].episodeUrl}
						hours={this.state.courses[i].categories}
						price={this.state.courses[i].episode}
						categories={this.state.courses[i].categories} ></Course>
					);
			}

		}
		return courseTiles;
	}

	getCoursesDetails() {
		const arrayLength = this.state.courses.length;
		let courseTiles = [];
		for (var i = 0; i < arrayLength; i++) {
			if (this.state.courses[i] != null) {
				courseTiles.push(<CourseDetails id={i}
					name={this.state.courses[i].name}
					description={this.state.courses[i].description}
					imageUrl={this.state.courses[i].imageUrl}
					spotsAvailable={this.state.courses[i].spotsAvailable}
					instructor={this.state.courses[i].instructor}
					instructorSM={this.state.courses[i].instructorSM}
					instructorAvatar={this.state.courses[i].instructorAvatar}
					hours={this.state.courses[i].hours}
					price={this.state.courses[i].price}
					categories={this.state.courses[i].categories} ></CourseDetails>);
			}

		}
		return courseTiles;
	}

	render() {
		return (
				<section className="course-section spad" >
					<div className="container">
						<div className="section-title mb-0" id="start">
							<h2>{this.props.title}</h2>
							<div className="course-author">
							<a href="https://open.spotify.com/show/1BeAW7hI9MSdloLx7mnQUQ?si=9d426df2fada42fb" target="_blank" rel="noopener noreferrer"><img alt="spotify"  src={spotify}/></a>
						</div>
						</div>
					</div>
					<div className="course-warp">
						<ul className="course-filter controls">
							<li className="control active" data-filter="all">{resources.all}</li>
							<li className="control" data-filter=".Technology">{resources.technology}</li>
							<li className="control" data-filter=".Health">{resources.health}</li>
							<li className="control" data-filter=".Life">{resources.life}</li>
							<li className="control" data-filter=".Personal">{resources.personal}</li>
							<li className="control" data-filter=".Hobby">{resources.hobby}</li>
						</ul>
						<div className="row course-items-area">
							{this.getCourses()}
						</div>
					</div>
				</section>
		);
	}

}
export default Courses;