import React, { Component } from 'react';
import resources from '../../utils/resources/AppLocalizedStrings';

class HeaderImage extends Component {

    render() {

        return (
            <div>
                <section id="hero-section" className="page-info-section set-bg" style={{ 'backgroundImage': 'url("https://res.cloudinary.com/courses-site/image/upload/v1557168520/404_tjrht1.png")' }}>
                    <div className="container">
                        <div className="hero-text-image text-white">
                            <h2>{resources.notFound}</h2>
                        </div>
                    </div>
                </section>

            </div>
        );
    }

}
export default HeaderImage;