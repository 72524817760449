import React, { Component, Suspense } from 'react';
import Header from './components/Header/Header';
import HeroContent from './components/HeroContent/HeroContent';
import Courses from './components/Courses/Courses';
import Footer from './components/Footer/Footer';
import './css/font-awesome.min.css';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import {Route} from 'react-router-dom';
import CourseDetails from './components/Courses/CourseDetails';
import resources from './utils/resources/AppLocalizedStrings';
import notFound from './components/404/404';

const BlogEntries = React.lazy(() => import('./components/Blog/BlogEntries'));

class App extends Component {


  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Header></Header>
          <Switch>
            <Route path="/" exact component={HeroContent} />
            <Route path="/blog" exact 
                render={() => (
                <Suspense fallback={<div>Loading</div>}> 
                  <BlogEntries/> 
                </Suspense>) } />
            <Route path="/episodes/:id" exact component={CourseDetails} />
            <Redirect from="/inicio" to="/"></Redirect>
            <Route component={notFound}/>
          </Switch>
          <Route path="/" exact render={() => <Courses title={resources.ourCourses} subtitle={resources.ourCoursesDesc}/>} />
          <Footer></Footer>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
