import LocalizedStrings from 'react-localization';

let resources = new LocalizedStrings({
   es: {
      home: "Inicio",
      news: "Noticias",
      contact: "Contacto",
      heroText: "",
      heroSubText: "",
      ourCourses: "Episodios",
      moreCourses: "Más cursos",
      ourCoursesDesc: "Puedes encontrar el resto en spotify",
      all: "Todos",
      technology: "Technology",
      health: "Health",
      life: "Life",
      personal: "Personal",
      hobby: "Hobby",
      location: "Chihuahua, Chihuahua, Mexico",
      phone: "614-1-82-5638",
      email: "obedmhg@gmail.com",
      java: "Java",
      webLangs: "HTML / CSS / JS",
      enterpernur: "Aprende a emprender",
      digitalMarketing: "Digital Marketing",
      socialMedia: "Social Media",
      photoShop: "Photoshop",
      seo: "Search Engine Optimization",
      price: "Precio",
      hours: "Horas",
      students: "Estudiantes",
      learnMore: "¿Quieres Saber más?",
      fillForm: "Llena esta forma y nosotros nos comunicamos contigo.",
      moreInfo: "Más información",
      popular: "Popular",
      professionals: "Profesionales",
      bestInTown: "Aprende de los mejores, expertos en su area.",
      workedOn: "Ha trabajado en:",
      notFound: "404 Esta página no existe",
      recommendations : "Recomendaciones"
   },
   en: {
      home: "Home",
      news: "News",
      contact: "Contact",
      heroText: "",
      heroSubText: "",
      ourCourses: "Episodes",
      moreCourses: "More courses",
      ourCoursesDesc: "You can find the rest at spotify",
      all: "All",
      development: "Development",
      design: "Design",
      webDevelopment: "Web Development",
      personal: "Personal",
      free: "Free!",
      location: "Chihuahua, Chihuahua, Mexico",
      phone: "614-1-82-5638",
      email: "obedmhg@gmail.com",
      java: "Java",
      webLangs: "HTML / CSS / JS",
      enterpernur: "Learn to undertake",
      digitalMarketing: "Digital Marketing",
      socialMedia: "Social Media",
      photoShop: "Photoshop",
      seo: "Search Engine Optimization",
      price: "Price",
      hours: "Hours",
      students: "Students",
      learnMore: "Whant to learn more?",
      fillForm: "Fill this form and we will comunicate with you.",
      moreInfo: "More Info",
      popular: "Popular",
      notFound: "404 Page not found"
   }
});

resources.setLanguage('es');

export default resources;