import React, { Component } from 'react';
import spotify from '../../img/spotify.png';

class Course extends Component {

	render() {
		return (
			<div className={"mix col-lg-3 col-md-4 col-sm-6 " + this.props.categories} id={this.props.id}>
				<a href={"/episodes/" + this.props.id} id={"episodes_card_" +this.props.id}>
				<div className="course-item">
					<div className="course-thumb set-bg" style={{ backgroundImage: 'url(' + this.props.imageUrl + ')' }}>
						<div className="price"> #{this.props.id} </div>
					</div>
					<div className="course-info">
						<div className="course-text">
							<h5>{this.props.name}</h5>
							<p>{this.props.description}</p>
							<div className="students">{this.props.spotsAvailable}</div>
						</div>
						<div className="course-author">
							<a href={this.props.episodeUrl} target="_blank" rel="noopener noreferrer"><img alt="spotify" src={spotify}/></a>
						</div>
					</div>
				</div>
				</a>
			</div>
		);
	}

}
export default Course;