import React, { Component } from 'react';
import axios from '../../utils/axios/axios-data';
import resources from '../../utils/resources/AppLocalizedStrings';

class HeroContent extends Component {
    mainImages = [];

    state = {
        heroImages: []
    };

    getRandomHeroImage() {
        var arrayLength = this.state.heroImages.length;
        for (var i = 0; i < arrayLength; i++) {
            if (this.state.heroImages[i] != null) {
                this.mainImages.push(this.state.heroImages[i])
            }

        }
        return { 'backgroundImage': 'url(' + this.mainImages[Math.floor(Math.random() * arrayLength) + 0] + ')' };
    }

    componentWillMount() {
        axios.get('/hero-images.json')
            .then(response => {
                this.setState({ heroImages: response.data });
            });
    }


    render() {
        let section = "";

        if (this.state.heroImages.length > 0) {
            section = <div>
            <section id="hero-section" className="hero-section set-bg" style={this.getRandomHeroImage()}>
                <div className="container">
                    <div className="hero-text text-white">
                        <h2>{resources.heroText}</h2>
                        <p>{resources.heroSubText}</p>
                    </div>
                </div>
            </section>

        </div>
        }
        return section;
    }

}
export default HeroContent;