import React, { Component } from 'react';
import spotify from '../../img/spotify.png';
import apple from '../../img/apple.png';
import google from '../../img/google.png';
import amazon from '../../img/amazon-music.png';

class Footer extends Component {

	render() {
		return (
			//bootstrap footer
			<div>
			<footer className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-sm-3">
							<a href="https://open.spotify.com/show/1BeAW7hI9MSdloLx7mnQUQ?si=9d426df2fada42fb" target="_blank" rel="noopener noreferrer"><img alt="spotify"  src={spotify}/></a>
						</div>
						<div className="col-sm-3">
							<a href="https://podcasts.apple.com/co/podcast/from-zero-to-n/id1584666207?l=en" target="_blank" rel="noopener noreferrer"><img width="165px" height="40px" alt="apple"  src={apple}/></a>
						</div>
						<div className="col-sm-3">
							<a href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy82OTI1ZDY2OC9wb2RjYXN0L3Jzcw" target="_blank" rel="noopener noreferrer"><img width="165px" height="40px" alt="google"  src={google}/></a>
						</div>
						<div className="col-sm-3">
							<a href="https://music.amazon.com.mx/podcasts/64044734-2289-4c63-85b2-584117c702f6/from-zero-to-n" target="_blank" rel="noopener noreferrer"><img width="165px" height="40px" alt="amazon"  src={amazon}/></a>
						</div>
					</div>
				</div>
			</footer>
			</div>
		);
	}

}
export default Footer;