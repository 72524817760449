import React, { Component } from 'react';
import logo from '../../img/logo.png';
import resources from '../../utils/resources/AppLocalizedStrings';
import $ from 'jquery';
import {NavLink, Link} from 'react-router-dom';

class Header extends Component {

  componentDidMount() {
    $('.nav-switch').on('click', function (event) {
      $('.main-menu').slideToggle(400);
      event.preventDefault();
    });
  }

  render() {
    return (
      <div>
        <div id="preloder">
          <div class="loader"></div>
        </div>

        <header className="header-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="site-logo">
                  <Link to="/"><img src={logo} alt="" /></Link>
                </div>
                <div className="nav-switch">
                  <i className="fa fa-bars"></i>
                </div>
              </div>
              <div className="col-lg-9 col-md-9">
                <nav className="main-menu">
                  <ul>
                    <li><NavLink to="/" exact activeStyle={{color: "#dc3545"}}>{resources.home}</NavLink></li>
                    <li><NavLink to="/blog" exact activeStyle={{color: "#dc3545"}}>{resources.news}</NavLink></li>
                    <li><a href="https://obed.typeform.com/to/AyT6d6DW" target="_blank" rel="noopener noreferrer">{resources.contact}</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }

}
export default Header;